import React, {useRef, useState} from "react";
import {Drawer, message, Result, Table} from "antd";
import ReactJson from "react-json-view";
import {useData} from "../javascript/api";

/*
* Returns a Table and Drawer for viewing
* @param {List} data List of FHIR Resources for display
* @param {list} columns List of columns with information about which components of the data will be displayed
* @param {String} localKey key that allows clearing the corresponding data in the localStorage
 */
export default function TableList({columns,route,param = null,localKey}) {

  let pageSize = 10; // Size of viewable table entries on one page
  const fetchAmount = 500; // Amount of resources requested at once

  let forceRequest = useRef(false); // Variable to force a request, even if localStorage is available used for navigating to the next page of fetchAmount resources

  const [loading, setLoading] = useState(true); // State for showing a loading indicator
  const [currentPage, setCurrentPage] = useState(1); // the current table page
  const [resources, setResources] = useState([]); // list of resources with length of fetchAmount
  const [bundle,setBundle] = useState(null);


  // States for the Raw JSON view
  const [showDrawer,setShowDrawer] = useState(false);
  const [rawResource,setRawResource] = useState(null);

  // using UseRef for persistence, without re-rendering on change. Changing the currentFetchOffset shouldn't cause a rerender because it indirectly causes it by changing the tableContent
  let currentFetchOffset = useRef(0);

  // Cut the content for the current table page from the last fetched pack
  //let shownContent = resources? resources.slice(((currentPage%(fetchAmount/pageSize))-1)*pageSize,((currentPage%(fetchAmount/pageSize))*pageSize)-1): null;
  let shownContent = bundle && bundle.entry? bundle.entry.slice(((currentPage%(fetchAmount/pageSize))-1)*pageSize,((currentPage%(fetchAmount/pageSize))*pageSize)-1): null;

  //Fetch data according to the offset and the fetchAmount
  useData(setBundle,setResources,setCurrentPage,currentPage, setLoading, route,((param? param+"&" : "") + "_count=" + fetchAmount + "&_offset=" + currentFetchOffset.current),localKey,forceRequest.current, true);
  forceRequest.current =false;
  console.log({bundle});
  if(!bundle) {
    return <Result  status="error"
                    title="Data Request Failed"
                    subTitle="Please check if the server url is set correctly and that the server accepts requests.">
      {bundle}
    </Result>

  }
  
  //add column with the raw view button
  if(!(columns.some(column => column.title === "Raw view" ))) { // Check if the "Raw view" field already exists
      columns = columns.concat({
        title: 'Raw view', dataIndex: '', key: 'raw',
        render: (obj) => obj? ( // Check if null, if there is nothing to display, the columns stays empty
            <a
                onClick={() => {
                  setShowDrawer(true);
                  setRawResource(obj);
                }}
            >
              View raw
            </a>) : null
      });
    }

  return (
      <div>
        <Table
            dataSource={shownContent}
            columns={columns}
            rowKey={"id"}
            loading={loading}
            pagination={{
              pageSize:pageSize,
              total: bundle ? bundle.total : 0,
              current: currentPage,
              onChange: (page,pageSize) => {

               if(localKey) {
                 localStorage.setItem(localKey + "Page",page.toString());
               }

                setCurrentPage(page); //update page state for the pagechange
                //calculate the offset
                let calculatedFetchOffset = Math.floor(page /(fetchAmount/pageSize))*fetchAmount;
                //if the offset changes, update the stored offset and view the loading status (the fetch happens during rerender)
                if(calculatedFetchOffset < currentFetchOffset.current || calculatedFetchOffset > currentFetchOffset.current) {
                  currentFetchOffset.current = calculatedFetchOffset;
                  forceRequest.current = true;
                  setLoading(true);
                     }
                  }
              }}
            footer={() =>{
              const totalString = "Total: "+ (bundle && bundle.total ? bundle.total : 0);
              let fetchDate = (bundle && bundle.meta.lastUpdated ? new Date(bundle.meta.lastUpdated).toUTCString(): "unknown")
              const lastFetchString = "Last fetch: " + fetchDate;
              return totalString + ", " + lastFetchString;
            } }
        />
        {shownContent? // Button to clear the localStorage for this page
            <div>
              <p>Data stored in localStorage!</p>
              <a onClick={() => {
                localStorage.removeItem(localKey);
                message.success("Local data cache cleared");
                window.location.reload();}}>
                 Clear localStorage for this page
              </a>
          </div> : null}
        <Drawer
            title={"Resource View"}
            placement={"right"}
            closeable={true}
            onClose={() => {
              setShowDrawer(false);
              setRawResource(null);
            }}
            open={showDrawer}
            width={"40%"}
        >
          <ReactJson src={rawResource}/>
        </Drawer>
      </div>
  )
};

