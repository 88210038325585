import React from "react";
import {Avatar, message, Popconfirm, Popover,} from "antd";
import {CaretDownOutlined, ExclamationCircleOutlined, UserOutlined} from "@ant-design/icons";
import {clearLocalStorage} from "../javascript/api";

const confirmLogout = () => {
  clearLocalStorage();
  // LOGOUT FUNCTIONALITY
};

export default function AccountCorner({auth}) {
  console.log({auth});
  if(auth && auth.redirect_url) {
    return <Popover title="You are not logged in!" content={<button onClick={() =>  window.location.href = auth.redirect_url}>Log in</button>}  placement="bottomRight" arrowPointAtCenter={true}>
      <ExclamationCircleOutlined style={{marginLeft: "10px", marginRight: "10px", fontSize:'24px', color: "#f55d42"}} />
      </Popover>
  }

  return ( // TODO PROPER LOGOUT MANAGMENT
      <Popconfirm
          placement="bottomRight"
          title="Would you like to logout of the system?"
          onConfirm={() => confirmLogout()}
          onCancel={() => {
            message.error('Canceled logout')
          }}
          okText="Logout"
          cancelText="Cancel"
      >
      <UserOutlined />
        <CaretDownOutlined/>
      </Popconfirm>
  )
}