import React, {useEffect, useState} from "react";
import {Bar, Pie} from "react-chartjs-2";
import {requestFromServer} from "../javascript/api";
import {Card, Col, Row} from "antd";
export default function StatsPage() {
  const [statData, setStatData] = useState(null);
  useEffect(() => {
    console.log("R")
    let ignore = false;
    requestFromServer("statistics").then(result => {
      if (!ignore) {
        setStatData(result);
      }
    });
    return () => {
      ignore = true;
    };
  }, []);

  function hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }


  console.log({statData})
  // If the data is available draw the plots
  if(statData) {
    let colors = [];
    const n =  Object.keys(statData.birthyears).length;
    console.log(statData.length)
    const increment = 360 / n;

    for (let i = 0; i < n; i++) {
      const hue = (i * increment) % 360;
      const rgb = hslToRgb(hue / 360, 1, 0.5);
      colors.push(`rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]},0.7)`);
    }

  const birthLabels = Object.keys(statData.birthyears);
  const birthData = {
    labels: birthLabels,
    datasets: [{
      label: 'Amount patients born this year',
      data: Object.values(statData.birthyears),
      backgroundColor: colors,
    }]
  };
    const resourceLabels = Object.keys(statData.resourceCount);
    const resourceData = {
      labels: resourceLabels,
      datasets: [
          {
        label: 'Amount of this resource on the server',
        data: Object.values(statData.resourceCount),
        backgroundColor: colors,
      }
      ]
    };

    const top10Labels = Object.keys(statData.top10resources);
    const top10Data = {
      labels: top10Labels,
      datasets: [
        {
          label: 'Amount of this resource on the server',
          data: Object.values(statData.top10resources),
          backgroundColor: [
            "#FF6384", "#36A2EB",
            "#FFCE56","#4BC0C0", "#9966FF", "#FF9F40", "#DC3912", "#109618", "#FF9900", "#990099", "#0099C6"],
        }
      ]
    };

    console.log({resourceData})
    return (
        <Row>
          <Col span={12}><Card title={"Birthyears of the patients"} extra={<a href="#">recalculate</a>}> <Bar data={birthData}></Bar> </Card></Col>
          <Col span={12}><Card title={"Amounts of each available resource"} extra={<a href="#">recalculate</a>}> <Bar data={resourceData}></Bar> </Card></Col>
          <Col span={12}><Card title={"Top 10 most common resources"} extra={<a href="#">recalculate</a>}><Pie data={top10Data}></Pie> </Card></Col>
        </Row>
    )
  }
  return null;
}
