import {useEffect, useState} from "react";
import {User} from "oidc-client-ts";

// Request Parameters
const SERVER_URL = process.env.REACT_APP_API_URL !== undefined ? process.env.REACT_APP_API_URL : "";
console.log({SERVER_URL})
const requestHeaders = new Headers({
  "authorization": ""
});


function useSession(){

  const AUTH_SERVER_URL = "https://c107-055.cloud.gwdg.de/api/check_session";
  
  const [isAuth, setIsAuth] = useState(null);
  useEffect(() => {
    fetch(AUTH_SERVER_URL)
      .then((response) => response.json())
      .then((data) => {
        console.log({data})
        if(data !== isAuth) {
          setIsAuth(data)
        }
      })
      .catch((error) => {
        console.error('Error fetching the URL:', error);
      });

  },[]);

  return isAuth;
}


//----------------------------------------------------------------------------------------------------------------------

/*
* Get the entries of a bundle as a list
* @param {Object} bundle FHIR-search bundle
* @return {List} Returns an list with the resources or null if no entries are present
 */
function bundleToList(bundle) {
  if (bundle && bundle.entry) {
    return bundle.entry.map(entry => entry.resource); // Extract all the entries into an array of resources
  } else {
    console.log("bundleTOLIST")
    console.log({bundle})
    return [];
  }
}

/*
* Route based fetch from the backend server
* @param {String} route The route that shall be requested.
* @param {String} token JWT token to be used for authentication with the backend server.
* @param {String} param Additional parameters, will be sent as the query_string of the request.
* @return {Promise} Returns a promise containing the JSON Formatted Bundle of Resources, returns null if the token is null.
*/
function requestFromServer(route, param=null) {

  // Set the access token
  const user = getUser();
  const token = user?.access_token;
  requestHeaders.set("Authorization", `Bearer ${token}`);
  requestHeaders.set('Content-Type', 'application/json');

  return new Promise((resolve, reject) => {
    fetch(SERVER_URL + route + (param? "?" + param : ""), {headers: requestHeaders})
        .then(async res => {
          if (!res.ok) {
            const e = new Error("Request failed with status:" + res.status)
            console.log(e);
            return e;
          }
          resolve(await res.json());
        })
        .catch(e => {
          console.log("error requesting resources from " + SERVER_URL + route)
          console.log(e);
          return e;
        });
  });
}

/*
* Custom hook for synchronizing the page (on page load) with data that's requested form the backend server. Loads data from localStorage if key is passed and data is available inside localStorage
* @param {List} [data,setData] useStates for storing and setting the requested data
* @param {String} route The route shat shall be requested
* @param {String} token JWT token, as a string, to be used for authentication with the backend server.
* @param {Object} param Optional parameters, will be sent as the query_string of the request.
* @param {Object} key Optional localstorage key, allows for first trying to load data from localstorage if there is data
* @return {Object} Returns an object that contains the JSON formatted FHIR Bundle.
 */
function useData(setBundle, setData, setPage, page, setLoading, route, param=null,key=null, forceRequest = false, asList=true) {
  let localData =null;
  let localDataPage = null;

  if(key) {
    localData = localStorage.getItem(key);
    localDataPage = localStorage.getItem(key + "Page");
  }

  useEffect(() => {
    let ignore = false;
    let requestNecessary = true;
    if(key && localData && localDataPage && !forceRequest) { //Check if data is already available in local storage and no request should be forced
      console.log("Loading " + key + " from localStorage");
      setLoading(false);
      setBundle(JSON.parse(localData));
      setPage(Number(localDataPage));
      requestNecessary = false;
    }
    if (route && requestNecessary) {

      requestFromServer(route, param ? param + "&_total=accurate" : "?_total=accurate") // add total accurate to receive an accurate amount of matching resources, this is necessary for the paging
          .then(res => {
            if (!ignore) {
              setLoading(false);
              if(key) { // Only store if a key is passed
                //localStorage.setItem(key, JSON.stringify(bundleToList(res)));
                localStorage.setItem(key,JSON.stringify(res));
                localStorage.setItem(key + "Page",page.toString());
              }

              // Save the data in the given state
              if(asList) {
                setData(bundleToList(res));
                setBundle(res);
              }
              else {
                setData(res);
                setBundle(res);
              }
            }
          })
          .catch( e=>{
            setBundle(e)
            console.log("ERROR IS CATCHED")
            return e; //TODO proper error handling
          })
      return () => {
        ignore = true;
      };
    }
  }, [route, param]);

}

/*
* Get the token from the localstorage
 */
function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:https://c108-013.cloud.gwdg.de/realms/FHIR-Frontend:fhir-frontend-test`); // TODO hardcoded realm/oidc url
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}


/*
* Clears resources stored in localStorage, used when logging out. Currently, clears the Devices and Patients
* @return null
 */
function clearLocalStorage() {
  localStorage.removeItem("Devices");
  localStorage.removeItem("DevicesPage");
  localStorage.removeItem("Patients");
  localStorage.removeItem("PatientsPage");
}

export {
  bundleToList,
  requestFromServer,
  useData,
  clearLocalStorage,
  useSession,
};
